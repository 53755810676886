import React from "react";

import { useState } from "react";
import { Radio, RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";

const frequencies = [
  { value: "fivePack", label: "5 Sessions", priceSuffix: "/5 sessions" },
  { value: "tenPack", label: "10 Sessions", priceSuffix: "/10 sessions" },
];

const tiers = [
  {
    name: "Drop-In Class",
    id: "tier-drop-in",
    href: {
      fivePack:
        "https://squareup.com/appointments/book/classes/b0fffinru221y3/LEJKQ0CJWABX4/classes",
      tenPack:
        "https://squareup.com/appointments/book/classes/b0fffinru221y3/LEJKQ0CJWABX4/classes",
    },
    price: { fivePack: "$27", tenPack: "$27" },
    description: "Dedicated support and infrastructure for your company.",
    features: [
      "Unlimited products",
      "Unlimited subscribers",
      "Advanced analytics",
      "1-hour, dedicated support response time",
      "Marketing automations",
      "Custom reporting tools",
    ],
    mostPopular: false,
  },
  {
    name: "Group",
    id: "tier-group",
    href: {
      fivePack: "https://square.link/u/hFEAAaXT",
      tenPack: "https://square.link/u/MgoETvB0",
    },
    price: { fivePack: "$125", tenPack: "$200" },
    description:
      "Elevate your workouts and take the next step towards achieving your fitness goals.",
    features: ["5 products", "Up to 1,000 subscribers", "Basic analytics"],
    mostPopular: true,
  },

  {
    name: "Semi-Private",
    id: "tier-semi-private",
    href: {
      fivePack: "https://square.link/u/zXS2XCcX",
      tenPack: "https://square.link/u/9RG0Bujs",
    },
    price: { fivePack: "$200", tenPack: "$350" },
    description:
      "Benefit from 1:1 tailored instruction, increased flexibility, and a customized workout designed just for you.",
    features: [
      "5 products",
      "Up to 1,000 subscribers",
      "Basic analytics",
      "48-hour support response time",
    ],
    mostPopular: false,
  },
  {
    name: "Private",
    id: "tier-private",
    href: {
      fivePack: "https://square.link/u/tEYSYh52",
      tenPack: "https://square.link/u/0Q2M44UC",
    },
    price: { fivePack: "$250", tenPack: "$450" },
    description:
      "Benefit from 1:1 tailored instruction, increased flexibility, and a customized workout designed just for you. ",
    features: [
      "25 products",
      "Up to 10,000 subscribers",
      "Advanced analytics",
      "24-hour support response time",
      "Marketing automations",
    ],
    mostPopular: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PricingTable() {
  const [frequency, setFrequency] = useState(frequencies[0]);

  return (
    <div className="bg-gray-900 pb-24 sm:pb-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <p className="mt-12 lg:mt-0 text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">
            Pilates Reformer Pricing
          </p>
        </div>
        <div className="mt-5 flex justify-center">
          <fieldset aria-label="Payment frequency">
            <RadioGroup
              value={frequency}
              onChange={setFrequency}
              className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
            >
              {frequencies.map((option) => (
                <Radio
                  key={option.value}
                  value={option}
                  className="cursor-pointer rounded-full px-2.5 py-1 text-gray-200 data-[checked]:bg-purple-700 data-[checked]:text-white"
                >
                  {option.label}
                </Radio>
              ))}
            </RadioGroup>
          </fieldset>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-2xl grid-cols-1 sm:grid-cols-2 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular
                  ? "ring-2 ring-purple-700"
                  : "ring-1 ring-gray-200",
                "rounded-3xl p-8",
              )}
            >
              <h3
                id={tier.id}
                className={classNames(
                  tier.mostPopular ? "text-pink-600" : "text-gray-100",
                  "text-lg font-semibold leading-8",
                )}
              >
                {tier.name}
              </h3>
              {/* <p className="mt-4 text-sm leading-6 text-gray-100">{tier.description}</p> */}
              <p className="mt-2 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-gray-100">
                  {tier.price[frequency.value]}
                </span>
                <span className="text-sm font-semibold leading-6 text-gray-200">
                  {/* {frequency.priceSuffix} */}
                  {tier.name === "Drop-In Class"
                    ? "per session"
                    : frequency.priceSuffix}
                </span>
              </p>
              <a
                href={tier.href[frequency.value]}
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? "bg-pink-600 text-white shadow-sm hover:bg-purple-600"
                    : "text-pink-600 ring-1 ring-inset ring-pink-600 hover:ring-pink-300",
                  "mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600",
                )}
              >
                {tier.name === "Drop-In Class" ? "Buy Session" : "Buy package"}
              </a>
              {/* <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-100">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-pink-600" />
                    {feature}
                  </li>
                ))}
              </ul> */}
            </div>
          ))}

        </div>

      </div>

    </div>
  );
}
