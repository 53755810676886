import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Contact from "../components/contact";
import HeroSection from "../components/heroSection";
import Banner from "../components/banner";
import Colophon from "../components/colophon";
import PricingTable from "../components/pricing";
import Intro from '../components/intro';

function Index() {
  return (
    <Layout>
      <Seo title="B-Aerial Pilates and Training Studio" />
      {/* <HeroSection/> */}

      {/* <Banner /> */}


      <Intro />
      <PricingTable />
      <Colophon />
    </Layout>
  );
}

export default Index;
