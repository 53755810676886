import {
  EnvelopeIcon,
  PhoneIcon,
  ClockIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import ContactForm from "./form";
import { StaticImage } from "gatsby-plugin-image";
import Social from "./social";

export default function Intro() {
  return (
    <div className="relative bg-gray-900" id="contact">
      <div className="absolute inset-0 overflow-hidden" aria-hidden="true">
        <div className="absolute left-[calc(50%-19rem)] top-[calc(50%-36rem)] transform-gpu blur-3xl">
          <div
            className="aspect-[1097/1023] w-[68.5625rem] bg-gradient-to-r from-[#f21d92] to-[#9c0dd9776fff] opacity-25"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
      <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-2">
        <div className="pb-1 px-5 lg:col-span-1 lg:pb-12 lg:pt-5 ">
          <div className="mx-auto max-w-2xl lg:max-w-xl  lg:px-0 lg:pb flex flex-col gap-y-2 ">
            <h2 className="text-2xl font-bold tracking-white text-white sm:text-3xl">
              We are excited to announce the launch of our studio in Sparta!
            </h2>
            <h3 className="text-md tracking-wide text-gray-200 sm:text-lg">
              We are currently offering children’s aerial/ cirque programs, children’s recreational dance, Reformer Pilates, Mom and baby Pilates, open gym and PA Days. Follow along to keep in the loop as we will be adding more classes and updates soon!
            </h3>
          <div className='pt-3 text-gray-200'>
            <h4 className="text-xl font-bold tracking-wide text-white sm:text-2xl pb-3">
              {" "}
              Pilates Reformer Classes
            </h4>
            <p className="text-white">
              Contact us for your intro to reformer consultation and to purchase
              packages.
            </p>
            <ul className="flex flex-col">
              <li className="text-lg leading-8 items-center flex">
                <PlusIcon className="h-5 w-auto pr-2" /> Group Classes
              </li>
              <li className="text-lg leading-8  flex items-center">
                <PlusIcon className="h-5 w-auto pr-2" />
                Semi Private
              </li>
              <li className="text-lg leading-8 flex items-center">
                <PlusIcon className="h-5 w-auto pr-2" />
                Private
              </li>
            </ul>
            </div>
          </div>
        </div>
        <div className="pb-1 px-5 lg:col-span-1 lg:pb-12 lg:pt-5 ">
          <div class="mx-auto max-w-2xl lg:max-w-xl  lg:px-0 lg:pb flex flex-col gap-y-2">
            <div class="relative px-0 lg:px-5">
            <div class="absolute -left-5  top-0 h-full w-0.5 bg-gradient-to-b from-pink-600 via-purple-700 to-purple-900 hidden lg:block"></div>
            <div class="relative border-l-2 border-transparent">
              <div className="mx-auto lg:max-w-none text-white flex flex-col gap-y-2">
                <h2 className="text-2xl font-bold tracking-white text-white sm:text-3xl pt-3 lg:pt-0">
                  Studio Rentals Now Available!
                </h2>
                <h3 className="text-md tracking-wide text-gray-200 sm:text-lg">
                  Starting in October, our studio will be available for aerial
                  and training rentals. If you're interested in renting our
                  space, reach out for more details.
                </h3>
                <h2 className="text-xl font-bold tracking-white text-white sm:text-2xl">
                  Stay Connected!
                </h2>
                <h3 className="text-md tracking-wide text-gray-200 sm:text-lg">
                  To stay updated on our current classes and open gym times,
                  follow us on Instagram and Facebook.
                </h3>
                <Social />
                <h3 className="text-md tracking-wide text-primary sm:text-lg">
                  For more information on classes and studio rentals, don't
                  hesitate to contact us.
                </h3>
                <div className="flex">
                  <EnvelopeIcon className="h-6 pr-2" />
                  <a href="mailto:info@baerial.com">info@baerial.com</a>
                </div>
                <div className="flex">
                  <PhoneIcon className="h-6 pr-2" />
                  <a href="tel:519-318-5887">519-318-5887</a>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
